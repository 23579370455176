<!--税负案例 lh 20211109-->
<template>
    <div class="base-container">
        <el-main>
            <div>
                <div class="top_title">税负案例</div>
            </div>
            <div class="chart_box">
                <p class="title">请输入以下相关参数，以便系统匹配更精确的税负案例。</p>
                <div class="feed_form">
                    <div class="feed_left">
                        <div class="feed_question">平均售价（元/平方米）：</div>
                        <div class="feed_question">建筑面积（平方米）：</div>
                        <div class="feed_question">单位成本（元/平方米）：</div>
                    </div>
                    <div class="feed_right">
                        <el-input type="number" v-model="price"></el-input>
                        <el-input type="number" v-model="area"></el-input>
                        <el-input type="number" v-model="cost"></el-input>
                    </div>
                </div>
                <el-button class="confim_btn" type="primary" size="small" @click="next()">确定</el-button>
            </div>
        </el-main>
    </div>
</template>

<script>
import {getStore, setStore,getLocalStore} from '@/util/store'
import {mapMutations, mapState} from "vuex";

export default {
    components: {
    },
    data() {
        return {
            price: '',
            area: '',
            cost: '',
            id: '',
        }
    },
    created() {
        this.getCase();
        this.getReport(0,'','')
    },
    destroyed(){
        this.getReport(1,'','')
    },
    mounted() {
    },
    methods: {
        ...mapMutations(["addPath", "addMenuList"]),
        //展现点击上报
        getReport(recordType,modelName,clickName){
            this.axios.request({
                method: "post", 
                url: '/dms/saveUserBehaviorRecord',
                 
                data: {
                    pageName:'taxCase',
                    userName:getLocalStore('areainfo').userName,
                    recordType:recordType,
                    modelName:modelName,
                    clickName:clickName,
                    userId:getLocalStore('areainfo').id,
                    ipAddress:getLocalStore('areainfo').ipAddress,
                    userAddress:getLocalStore('areainfo').userAddress,
                    ubr: getStore('reportobj')||{},
                },
            }).then((res) => {
               setStore('reportobj',res.data.data)
            })
        },
        getCase() {
            this.axios({
                method: "get", 
                url: '/dms/taxationInfo/'+getStore('thisProject').id,
                 
            }).then((res) => {
                this.cost =  res.data.data.costUnit;
                this.price =  res.data.data.salePrice;
                this.area =  res.data.data.area;
                this.id = res.data.data.id;
                setStore("caseData", res.data.data);

                //如果曾经录入过,直接 进入下个页面
                if(res.data.data.isConfirm){
                    this.addMenuList("/taxCaseTable");
                    setStore("currentMenu", "/taxCaseTable");
                    this.$router.push({path: "/taxCaseTable"});
                }
            }).catch((error) => {
                console.log(error)
            })
        },
        //更新数据
        next(){
            this.getReport(2,'taxParameter','confirmBtn');
            this.axios({
                method: "put", 
                url: '/dms/taxationInfo/'+this.id,
                 
                data:{
                    id: this.id,
                    projectId: getStore('thisProject').id,
                    costUnit: this.cost,
                    salePrice: this.price,
                    area: this.area,
                }
            }).then((res) => {
                if(res.data.status=="success"){
                    setStore("caseData", res.data.data);
                    this.$message.success('更新成功！')
                     // console.log(this.price,this.area,this.cost);
                    this.addMenuList("/taxCaseTable");
                    setStore("currentMenu", "/taxCaseTable");
                    this.$router.push({path: "/taxCaseTable"});
                }else{
                    if(res.data.msg=='tokenMissing'||res.data.msg=='tokenExpired'||res.data.msg=='tokenIllegal'){
                       
                    }else{
                        this.$message.error(res.data.msg);
                    }
                }
            }).catch((error) => {
                console.log(error)
            })
           
        }
  
    }
}
</script>

<style scoped lang="less">
.el-main{
    width: 1200px;
    min-height: 600px;
    margin: 0 auto;
    padding: 0 !important;
    background-color: #fff;
    .top_title{
        height: 60px;
        line-height: 60px;
        padding-left: 20px;
        box-sizing: border-box;
        font-weight: 600;
        color: #333333;
        font-size: 16px;
        border-bottom: 1px solid #EBEBEB;
    }
}
.title{
    text-align: center;
    margin-top: 102px;
}
.feed_form{
    width: 400px;
    margin: 0 auto;
    overflow: hidden;
    padding-top: 40px;
    box-sizing: border-box;
    .feed_left{
        width: 160px;
        float: left;
        text-align: right;
        .feed_question{
            height: 32px;
            line-height: 32px;
            margin-bottom: 16px;
            >i{
                color: #D40000;
            }
        }
        .feed_proposal{
            padding-top: 6px;
            height: 74.4px;
            box-sizing: border-box;
            margin-bottom: 16px;
        }
    }
    .feed_right{
        width: 214px;
        float: right;
        .value{
            height: 32px;
            line-height: 32px;
            margin-bottom: 16px;
        }
    }
    .el-input{
        height: 32px;
        margin-bottom: 16px;
        /deep/.el-input__icon{
            line-height: 32px !important;
        }
    }
    /deep/.el-input__inner{
        width: 214px;
        height: 32px !important;
        line-height: 32px !important;
    }
}
.chart_box{
    text-align: center;
}
.confim_btn{
    margin-top: 200px;
    margin-bottom: 24px;
}
</style>