import { render, staticRenderFns } from "./taxCase.vue?vue&type=template&id=0a379f11&scoped=true&"
import script from "./taxCase.vue?vue&type=script&lang=js&"
export * from "./taxCase.vue?vue&type=script&lang=js&"
import style0 from "./taxCase.vue?vue&type=style&index=0&id=0a379f11&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0a379f11",
  null
  
)

export default component.exports